import { useContext, useState } from "react";

import {Redirect} from "react-router-dom";
import {Button, Grid, TextField, Typography} from "@material-ui/core";

import {AuthContext} from "services/providers/auth-provider";

import Menu from "components/menu";
import Error from "components/error";

import {useStyles} from "./login-styles";


const Login = (props) => {
  const {isAuthenticated, login} = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [wrong, setWrong] = useState(undefined);
  const [error, setError] = useState(undefined);

  const referer = props.location.state ? props.location.state.referer || '/' : "/";

  const classes = useStyles()

  const handleSubmit = (e) => {
    e.preventDefault()
    login({username: username, password: password}).catch(err => {
      if (err.response) {
        setWrong(err.response.data.errors)
        if (err.response.status === 500) {
          setError(true)
        }
      }
    })
  }

  if (error) {
    return <Error message={wrong}/>
  }
  if (isAuthenticated) {
    return <Redirect to={referer}/>
  }
  return (
    <Grid className="centerDiv">
      <Menu title={"Connexion"}/>
      <Grid container spacing={5} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>
            Merci de vous connecter avec vos identifiants Sewan
          </Typography>
        </Grid>
        {wrong &&
        <Grid item xs={12}>
          <Typography variant={"subtitle2"} color={"secondary"}>
            {wrong}
          </Typography>
        </Grid>
        }
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid item xs={12} className={classes.formInput}>
            <TextField
              required
              type={"text"}
              name={"username"}
              variant={"outlined"}
              label={"Login"}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} className={classes.formInput}>
            <TextField
              required
              type={"password"}
              variant={"outlined"}
              value={password}
              label={"Password"}
              onChange={e => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type={"submit"} color={"primary"} variant={"contained"}>Se connecter</Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Login;
