import axios from 'axiosConfig';

export const authLogin = (credentials) => {
  return fetch(window.API_URL + "/api/v1.0/set-csrf/", {method: "GET"}).then(_ => {
    return axios.post(window.API_URL + "/api/v1.0/login/", {
      username: credentials.username,
      password: credentials.password,
      userid: credentials.userid
    })
  })
}

export const authLogout = () => {
  return fetch(window.API_URL + "/api/v1.0/logout/", {method: "GET"})
}

export const checkIsAuthenticated = () => {
  return axios.get(window.API_URL + "/api/v1.0/check-auth/")
}

export const testModeApi = () => {
  return axios.get(window.API_URL + "/api/mode_api/")
}

export const sendTestModeApi = (checked) => {
  let form_data = new FormData();
  form_data.append('sewan_test_url', checked);
  return axios.post(window.API_URL + "/api/mode_api/", form_data, {})
}
